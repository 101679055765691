$theme-colors: (
  "primary": #2546bb,
  "secondary": #6c757d,
  "danger": #dc3545,
  "dark": #212529,
  "success": #198754,
  "info": #0dcaf0,
  "warning": #ff9307,
);
$input-placeholder-color: rgb(180, 180, 180);

@import "~bootstrap/scss/bootstrap";

html,
body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #252733;
  max-height: 100%;
  scrollbar-color: #2546bb;
  background-color: #edf0f6;
}

button {
  height: 46px;
}

input,
select {
  min-height: 45px;
}

.form-check-input {
  min-height: 10px;
}

.no-print {
  @media print {
    display: none;
  }
}

.text-input {
  outline: none;
  border: 1px solid #b6b6b6;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  padding: 10px 15px;
}

textarea:focus,
input:focus,
input[type]:focus,
select:focus,
.uneditable-input:focus {
  border-width: 2px;
  border-color: #2546bb !important;
  box-shadow: none !important;
  outline: 0 none;
}
.filter-select__value-container {
  height: 35px !important;
  max-height: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  font-weight: bold !important;
}

.filter-select__single-value {
  position: static !important;
  transform: none !important;
}

.filter-select__control {
  max-height: 35px !important;
  min-height: 35px !important;
  box-shadow: none !important;
}

.filter-select__placeholder {
  position: static !important;
  transform: none !important;
  margin-bottom: 10px;
  padding: 0 !important;
  margin: 0 !important;
}

.react-responsive-modal-modal {
  max-width: 90vw;
}

.react-select__control {
  border-radius: 0px !important;
  border: 1px black !important;
}

.support-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.support-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  font-size: 16px;
}

.support-button:hover {
  background-color: #0056b3;
}

.close-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  top: -20px;
  right: -22px;
  color: #ff0000;
}

@import url("https:/fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap");

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #2546bb;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}
*::-webkit-scrollbar-thumb {
  background-color: #2546bb;
  border-radius: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

// @tailwind base;
// @tailwind components;
// @tailwind utilities;

$theme-colors: (
  "primary": #2546bb,
  "secondary": #6c757d,
  "danger": #dc3545,
  "dark": #212529,
  "success": #198754,
  "info": #0dcaf0,
  "warning": #ff9307,
);
$input-placeholder-color: rgb(180, 180, 180);

@import "~bootstrap/scss/bootstrap";

html,
body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #252733;
  max-height: 100%;
  scrollbar-color: #2546bb;
  background-color: #edf0f6;
}

button {
  height: 46px;
}

input,
select {
  min-height: 45px;
}

.form-check-input {
  min-height: 10px;
}

.no-print {
  @media print {
    display: none;
  }
}

.text-input {
  outline: none;
  border: 1px solid #b6b6b6;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  padding: 10px 15px;
}

textarea:focus,
input:focus,
input[type]:focus,
select:focus,
.uneditable-input:focus {
  border-width: 2px;
  border-color: #2546bb !important;
  box-shadow: none !important;
  outline: 0 none;
}
.filter-select__value-container {
  height: 35px !important;
  max-height: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  font-weight: bold !important;
}

.filter-select__single-value {
  position: static !important;
  transform: none !important;
}

.filter-select__control {
  max-height: 35px !important;
  min-height: 35px !important;
  box-shadow: none !important;
}

.filter-select__placeholder {
  position: static !important;
  transform: none !important;
  margin-bottom: 10px;
  padding: 0 !important;
  margin: 0 !important;
}

.react-responsive-modal-modal {
  max-width: 90vw;
}

.react-select__control {
  border-radius: 0px !important;
  border: 1px black !important;
}

.support-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.support-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  font-size: 16px;
}

.support-button:hover {
  background-color: #0056b3;
}

.close-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  top: -20px;
  right: -22px;
  color: #ff0000;
}

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap");

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #2546bb;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}
*::-webkit-scrollbar-thumb {
  background-color: #2546bb;
  border-radius: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

// @tailwind base;
// @tailwind components;
// @tailwind utilities;
